import React from 'react';
import { Box, Typography } from '@mui/material';

const Legend = ({ selectedGroups, groupColors }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 16,
        right: 16,
        backgroundColor: '#fff',
        padding: 2,
        borderRadius: 1,
        boxShadow: 3,
        zIndex: 1000
      }}
    >
      <Typography variant="h6" gutterBottom>Legend</Typography>
      {selectedGroups.map(group => (
        <Box key={group} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Box
            sx={{
              width: 24,
              height: 24,
              backgroundColor: groupColors[group],
              border: '1px solid #000',
              marginRight: 1
            }}
          />
          <Typography>{group}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Legend;
