import React, { useState, useEffect } from 'react';
import DeckGL from '@deck.gl/react';
import { Map } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import { HtmlOverlay } from '@nebula.gl/overlays';
import { HtmlOverlayItem } from '@nebula.gl/overlays';
import PieChartComponent from './PieChartComponent';
import { Slider, Box, Typography, CssBaseline, Drawer, Autocomplete, TextField, Accordion, AccordionSummary, AccordionDetails, Popover } from '@mui/material';
import { SketchPicker } from 'react-color';
import { ExpandMore } from '@mui/icons-material';
import Legend from './Legend';  // Import the Legend component

const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json';

const initialViewState = {
  longitude: 174.828626,
  latitude: -41.269700,
  zoom: 10,
  pitch: 0,
  bearing: 0
};

class CustomHtmlOverlay extends HtmlOverlay {
  getItems() {
    const { scaleRange, selectedGroups, minTotal, maxTotal, coordinates, groupColors } = this.props;

    return coordinates.map((coord, index) => {
      const filteredData = Object.entries(coord.properties)
        .filter(([key]) => selectedGroups.includes(key))
        .map(([key, value]) => ({ name: key, value }));

      const totalValue = filteredData.reduce((sum, item) => sum + item.value, 0);
      const scale = (totalValue - minTotal) / (maxTotal - minTotal) * (scaleRange[1] - scaleRange[0]) + scaleRange[0];

      const colors = filteredData.map(item => groupColors[item.name]);

      return (
        <HtmlOverlayItem
          key={index}
          coordinates={[coord.properties.lon, coord.properties.lat]}
          style={{ pointerEvents: 'auto' }}
        >
          <PieChartComponent data={filteredData} scale={scale} colors={colors} />
        </HtmlOverlayItem>
      );
    });
  }
}

const App = ({ mapStyle = MAP_STYLE }) => {
  const [scaleRange, setScaleRange] = useState([50, 100]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [minTotal, setMinTotal] = useState(0);
  const [maxTotal, setMaxTotal] = useState(0);
  const [coordinates, setCoordinates] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [groupColors, setGroupColors] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);

  useEffect(() => {
    // Fetch the GeoJSON data
    fetch('/wtsm_sector.geojson')
      .then(response => response.json())
      .then(data => {
        const features = data.features;

        const groups = new Set();
        features.forEach(feature => {
          Object.keys(feature.properties).forEach(key => {
            if (key !== 'lat' && key !== 'lon' && key !== 'ID') {
              groups.add(key);
            }
          });
        });

        const groupsArray = Array.from(groups);
        setAllGroups(groupsArray);
        setSelectedGroups(groupsArray); // Set initial selected groups to all groups

        // Initialize colors for each group
        const initialColors = {};
        groupsArray.forEach(group => {
          initialColors[group] = '#'+((1<<24)*Math.random()|0).toString(16); // Random initial color
        });
        setGroupColors(initialColors);

        setCoordinates(features);

        const totals = features.map(feature =>
          Object.entries(feature.properties)
            .filter(([key]) => groupsArray.includes(key))
            .reduce((sum, [, value]) => sum + value, 0)
        );
        setMinTotal(Math.min(...totals));
        setMaxTotal(Math.max(...totals));
      })
      .catch(error => console.error('Error loading GeoJSON data:', error));
  }, []);

  const handleScaleChange = (event, newValue) => {
    setScaleRange(newValue);
  };

  const handleGroupChange = (event, newValue) => {
    setSelectedGroups(newValue);
  };

  const handleColorChange = (color) => {
    if (currentGroup) {
      setGroupColors(prevColors => ({
        ...prevColors,
        [currentGroup]: color.hex
      }));
    }
  };

  const handlePopoverOpen = (event, group) => {
    setAnchorEl(event.currentTarget);
    setCurrentGroup(group);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setCurrentGroup(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: 400,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 400,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6">Settings</Typography>
          <Typography gutterBottom>Pie Chart Scale</Typography>
          <Slider
            value={scaleRange}
            min={20}
            max={500}
            step={10}
            onChange={handleScaleChange}
            valueLabelDisplay="auto"
            marks={[
              { value: 20, label: '20' },
              { value: 500, label: '500' }
            ]}
          />
          <Typography gutterBottom>Select Attributes</Typography>
          <Autocomplete
            multiple
            options={allGroups}
            value={selectedGroups}
            onChange={handleGroupChange}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Attributes" placeholder="Select attributes" />
            )}
          />
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Color Picker</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {selectedGroups.map(group => (
                <Box key={group} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography sx={{ flexGrow: 1 }}>{group}</Typography>
                  <Box
                    sx={{
                      width: 24,
                      height: 24,
                      backgroundColor: groupColors[group],
                      border: '1px solid #000',
                      cursor: 'pointer'
                    }}
                    onClick={(e) => handlePopoverOpen(e, group)}
                  />
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DeckGL
          initialViewState={initialViewState}
          controller={true}
          layers={[]}
        >
          <Map reuseMaps mapLib={maplibregl} mapStyle={mapStyle} preventStyleDiffing={true} />
          <CustomHtmlOverlay scaleRange={scaleRange} selectedGroups={selectedGroups} minTotal={minTotal} maxTotal={maxTotal} coordinates={coordinates} groupColors={groupColors} />
        </DeckGL>
        <Legend selectedGroups={selectedGroups} groupColors={groupColors} />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SketchPicker
          color={currentGroup ? groupColors[currentGroup] : '#fff'}
          onChangeComplete={handleColorChange}
        />
      </Popover>
    </Box>
  );
};

export default App;
